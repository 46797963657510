<template>
  <div class="container-xxl text-center py-34 py-md-120">
    <div class="error-code text-primary fw-bold">
      404
    </div>
    <h1 class="mt-15 mt-md-34 mb-10 fw-bold">
      К сожалению, страница не найдена
    </h1>
    <div class="mb-15 mb-md-34">
      Нам очень жаль за предоставленные неудобства. Похоже, вы пытаетесь получить доступ к странице, которая либо была удалена, либо вообще никогда не существовала.
    </div>
    <NuxtLink
      class="btn btn-primary rounded-20"
      to="/"
    >
      На главную
    </NuxtLink>
  </div>
</template>

<style scoped lang="scss">
.error-code {
  font-size: 150px;
  line-height: 1;
}
</style>